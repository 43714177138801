import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartEmpty } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import "./ApartmentsCollection.css";
import Loader from "../../../Loader/Loader";
import { Rating } from "react-simple-star-rating";

function ApartmentsCollection(props) {
  const [apartments, setApartments] = useState([]);
  const [premiumLoader, setPremiumLoader] = useState(true);
  /**LANGUAGE SETTINGS */
  const { t } = useTranslation();

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.id;
  const userToken = user?.token;

  const [wishlist, setWishlist] = useState([]);

  useEffect(() => {
    try {
      axios
        .get(`https://citynew.onrender.com/user/${userId}`)
        .then((response) => {
          setWishlist(response.data.wishlist);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, [userId]);

  useEffect(() => {
    axios
      .get("https://citynew.onrender.com/appartments/getAllAppart")
      .then((result) => {
        setApartments(
          result.data.map((apartment) => ({ ...apartment, liked: false }))
        );

        // Log the data here
        setPremiumLoader(false);
      })
      .catch((error) => console.log(error));
  }, []);

  // const handleLikeClick = (id) => {
  //   setApartments((prevApartments) =>
  //     prevApartments.map((apartment) =>
  //       apartment.id === id
  //         ? { ...apartment, liked: !apartment.liked }
  //         : apartment
  //     )
  //   );
  // };

  const handleLikeClick = (id) => {
    if (user) {
      setApartments((prevApartments) =>
        prevApartments.map((apartment) =>
          apartment.id === id
            ? { ...apartment, liked: !apartment.liked }
            : apartment
        )
      );

      if (wishlist.includes(id)) {
        fetch(`https://citynew.onrender.com/user/rmwishlist/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        })
          .then(() =>
            setWishlist((prevWishlist) =>
              prevWishlist.filter((item) => item !== id)
            )
          )
          .catch((error) => console.log(error));
      } else {
        fetch(`https://citynew.onrender.com/user/wishlist/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        })
          .then(() => setWishlist((prevWishlist) => [...prevWishlist, id]))
          .catch((error) => console.log(error));
      }
    } else {
      return;
    }
  };

  if (props.isFiltering) {
    return;
  }

  return (
    <>
      {premiumLoader ? (
        <Loader className={"percent-height"} />
      ) : (
        <section className="premium__collection__page">
          <motion.div
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.5 }}
          >
            <div className="premium_collection_items_title">
              <h2>{t("OUR APARTMENTS COLLECTION")}</h2>
              <div className="line-in-middle"></div>
            </div>
            <div className="premium_collection_items_content">
              <div className="premium_collection_content">
                <div className="row">
                  {apartments.map((data) => {
                    return (
                      <div className="col-sm-4" key={data.id}>
                        {" "}
                        <div className="card">
                          <div className="card_img">
                            <Link to={`/details/${data.id}`}>
                              <motion.img
                                whileHover={{ scale: 0.95 }}
                                whileTap={{ scale: 0.8 }}
                                whileInView={{ opacity: [0, 1] }}
                                transition={{ duration: 0.7 }}
                                src={data.img[0]}
                                height={250}
                                className="card-img-top"
                                alt="..."
                              />
                            </Link>
                          </div>
                          <div className="card_body">
                            <div className="like_button">
                              <button onClick={() => handleLikeClick(data.id)}>
                                <FontAwesomeIcon
                                  icon={
                                    wishlist.includes(data.id)
                                      ? faHeart
                                      : faHeartEmpty
                                  }
                                  className={`heart-icon ${
                                    wishlist.includes(data.id) ? "liked" : ""
                                  }`}
                                />
                              </button>
                            </div>
                            <div className="card_content">
                              <h3>{data.name}</h3>
                              <p>{data.description}</p>
                              <Rating
                                initialValue={data.rating}
                                readonly
                                allowFraction
                                size={25}
                              />
                              <br />
                              <strong>{data.pricePerNight}€</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </motion.div>
        </section>
      )}
    </>
  );
}

export default ApartmentsCollection;
