import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "./RequestsPage.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
import Swal from "sweetalert2";
import moment from "moment";
import Badge from "react-bootstrap/Badge";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";

function Requestspage() {
  const [orders, setOrders] = useState([]);
  const { t } = useTranslation();
  /**GET ALL ORDERS FOR THE LOGGED IN USER */
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("https://citynew.onrender.com/user/orders/GetallUO", {
        headers: {
          Authorization: `Bearer ${token}`, // if authentication is required
        },
      })
      .then((response) => {
        setOrders(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data); // handle error
      });
  }, [token]);

  /**CLICK TO PAY*/
  function handlePayNowClick(e, order) {
    e.preventDefault();
    const orderId = order.id;
    localStorage.setItem("orderId", orderId);
    // redirect to payment page
    window.location.href = "/paynow";
  }

  /**CANCEL/DELETE AN ORDER */
  function handleCancelClick(orderId) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          axios
            .delete(
              `https://citynew.onrender.com/user/reservations/decline/${orderId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`, // if authentication is required
                },
              }
            )
            // eslint-disable-next-line no-unused-vars
            .then((response) => {
              // Update the orders array in state to remove the deleted order
              setOrders(orders.filter((order) => order._id !== orderId));
              // Retrieve the updated list of orders from the server
              axios
                .get("https://citynew.onrender.com/user/orders/GetallUO", {
                  headers: {
                    Authorization: `Bearer ${token}`, // if authentication is required
                  },
                })
                .then((response) => {
                  setOrders(response.data);
                  setLoading(false);
                })
                .catch((error) => {
                  console.log(error.response.data); // handle error
                });
            })
            .catch((error) => {
              console.log(error);
            });

          // Show success message after deleting the order
          Swal.fire("Deleted!", "Your order has been canceled.", "success");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Show cancel message
          Swal.fire("Cancelled", "Your order is safe :)", "error");
        }
      });
  }

  return (
    <div className="requests__page">
      <Navbar />
      <div className="upper__space"></div>
      <div className="requests_page_content">
        <h1 className="text-light text-center my-3">
          {t("MY BOOKING REQUESTS")}
        </h1>
        {loading ? (
          <Loader className={"percent-height"} />
        ) : (
          <div className="requests_table">
            {orders.length > 0 ? (
              <Table responsive className="requests_table">
                {
                  <>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{t("Apartment")}</th>
                        <th>{t("Booking Date")}</th>
                        <th>{t("check-in")}</th>
                        <th>{t("check-out")}</th>
                        <th>{t("Total price")}</th>
                        <th>{t("Order status")}</th>
                        <th>{t("Actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((order, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{order.appartment.name}</td>
                          <td>
                            {moment(order.createdAt).format("DD MMMM YYYY")}
                          </td>
                          <td>
                            {moment(order.checkIn).format("DD MMMM YYYY")}
                          </td>
                          <td>
                            {moment(order.checkOut).format("DD MMMM YYYY")}
                          </td>
                          <td>€ {order.totalPrice}</td>
                          <td>
                            {order.state === "PENDING" && (
                              <div>
                                <Badge bg="warning" pill text="dark">
                                  {t("PENDING")}
                                </Badge>
                              </div>
                            )}
                            {order.state === "ACCEPTED" && (
                              <div>
                                <Badge bg="success" pill text="dark">
                                  {t("ACCEPTED")}
                                </Badge>
                              </div>
                            )}
                            {order.state === "DECLINED" && (
                              <div>
                                <Badge bg="danger" pill text="dark">
                                  {t("DECLINED")}
                                </Badge>
                              </div>
                            )}
                          </td>

                          <td>
                            {order.isPaied === true ? (
                              <span>{t("Already Paid")}</span>
                            ) : (
                              <div>
                                {order.state === "PENDING" && (
                                  <div>
                                    <button
                                      type="button"
                                      className="btn"
                                      style={{
                                        backgroundColor: "#dc3444",
                                        borderRadius: "16px",
                                        fontSize: "12px",
                                      }}
                                      onClick={() =>
                                        handleCancelClick(order.id)
                                      }
                                    >
                                      <span style={{ color: "white" }}>
                                        {t("DELETE")}
                                      </span>
                                    </button>
                                  </div>
                                )}
                                {order.state === "ACCEPTED" && (
                                  <div>
                                    <button
                                      type="button"
                                      className="btn"
                                      style={{
                                        backgroundColor: "#23639e",
                                        borderRadius: "16px",
                                        fontSize: "12px",
                                      }}
                                      onClick={(e) =>
                                        handlePayNowClick(e, order)
                                      }
                                    >
                                      <span style={{ color: "white" }}>
                                        {t("PAY NOW")}
                                      </span>
                                    </button>

                                    <p
                                      style={{
                                        marginLeft: "3%",
                                      }}
                                      className="link--metis link"
                                      onClick={() =>
                                        handleCancelClick(order.id)
                                      }
                                    >
                                      <span>{t("CANCEL")}</span>
                                    </p>
                                  </div>
                                )}
                                {order.state === "DECLINED" && (
                                  <div>
                                    <button
                                      type="button"
                                      className="btn"
                                      style={{
                                        backgroundColor: "#dc3444",
                                        borderRadius: "16px",
                                        fontSize: "12px",
                                      }}
                                      onClick={() =>
                                        handleCancelClick(order.id)
                                      }
                                    >
                                      <span style={{ color: "white" }}>
                                        {t("DELETE")}
                                      </span>
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                }
              </Table>
            ) : (
              <p>No orders found for this user!</p>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Requestspage;
