import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import AllAppartmentsRow from "./AllAppartmentsRow";
import { ToastContainer, toast } from "react-toastify";
import { Form } from "react-bootstrap";
import AppartmentDetailsModal from "./AppartmentDetailsModal";
import "./AppartmentDash.css";

export default function AllAppartments() {
  const [allAppartments, setAllAppartments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedAppartment, setSelectedAppartment] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [filterValue, setFilterValue] = useState("");
  let filteredAllAppartments = [];
  let filteredAppartments = filteredAllAppartments;
  const [value, setValue] = useState(0);
  // getAllAppart
  useEffect(() => {
    axios
      .get(`https://citynew.onrender.com/appartments/getAllAppart`)
      .then((response) => {
        setAllAppartments(response.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          " Ein Fehler ist aufgetreten, während versucht wurde, Wohnungen zu erhalten!",
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      });
  }, []);

  const handleShowDetails = (appart) => {
    setSelectedAppartment(appart);
    setShowModal(true);
  };

  // filter the orders array based on the selected filter value
  if (filterValue === "") {
    filteredAllAppartments = allAppartments;
  }
  if (value !== 0) {
    filteredAppartments = filteredAllAppartments.filter(
      // eslint-disable-next-line
      (appart) => appart.rooms == value
    );
  }
  // eslint-disable-next-line
  if (value == 0) {
    filteredAppartments = filteredAllAppartments;
  }

  return (
    <>
      <div className="all-apparts-cont">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Table responsive className="text-light">
          <thead>
            <tr>
              <th colSpan={6}></th>
              <th colSpan={1}>
                <Form.Control
                  type="number"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  min={0}
                  max={10}
                />
              </th>
            </tr>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Preis pro Nacht</th>
              <th>Ort</th>
              <th>Zimmer</th>
              <th>Bewertung</th>
              <th>Aktionen </th>
            </tr>
          </thead>
          <tbody>
            {filteredAllAppartments.length === 0 ? (
              <tr>
                <td colSpan={7}>
                  <p className="text-light text-center">
                    Keine passenden Wohnungen gefunden.
                  </p>
                </td>
              </tr>
            ) : (
              filteredAppartments.map((appart, index) => (
                <AllAppartmentsRow
                  appart={appart}
                  index={index}
                  handleShowDetails={handleShowDetails}
                  allAppartments={filteredAppartments}
                  setAllAppartments={setAllAppartments}
                />
              ))
            )}
          </tbody>
        </Table>
      </div>
      {selectedAppartment && (
        <AppartmentDetailsModal
          appartment={selectedAppartment}
          show={showModal}
          handleClose={() => {
            setSelectedAppartment(null);
            setShowModal(false);
          }}
        />
      )}
    </>
  );
}
