import React, { useState } from "react";
import "./Walkthrough.css";
import { useTranslation } from "react-i18next";
import Navbar from "../Navbar/Navbar";

const Walkthrough = () => {
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState(1);

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
    };
    
    // {t("")}

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <h2 className="step-text">
              {t("Step 1: Choose an apartment tier")}
            </h2>
            <div className="step-img-cont">
              <img
                src="steps/step-1.png"
                className="img-fluid step-img img-thumbnail"
                alt="step screenshot"
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <h2 className="step-text">
              {t("Step 2: Choose one of the apartments")}
            </h2>
            <div className="step-img-cont">
              <img
                src="steps/step-2.png"
                className="img-fluid step-img img-thumbnail"
                alt="step screenshot"
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <h2 className="step-text">
              {t("Step 3: Select the services you want (optional)")}
            </h2>
            <div className="step-img-cont">
              <img
                src="steps/step-3.png"
                className="img-fluid step-img img-thumbnail"
                alt="step screenshot"
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <h2 className="step-text">
              {t("Step 4: Select the reservation period")}
            </h2>
            <div className="step-img-cont">
              <img
                src="steps/step-4.png"
                className="img-fluid step-img img-thumbnail"
                alt="step screenshot"
              />
            </div>
          </div>
        );
      case 5:
        return (
          <div>
            <h2 className="step-text">
              {t("Step 5: Click on RESERVE button")}
            </h2>
            <div className="step-img-cont">
              <img
                src="steps/step-5.png"
                className="img-fluid step-img img-thumbnail"
                alt="step screenshot"
              />
            </div>
          </div>
        );
      case 6:
        return (
          <div>
            <h2 className="step-text">
              {t(
                "Step 6: Confirm your request by clicking on SEND REQUEST button"
              )}
            </h2>
            <div className="step-img-cont">
              <img
                src="steps/step-6.png"
                className="img-fluid step-img img-thumbnail"
                alt="step screenshot"
              />
            </div>
          </div>
        );
      case 7:
        return (
          <div>
            <h2 className="step-text">
              {t("Step 7: Wait for the request to be accepted")}
            </h2>
            <div className="step-img-cont">
              <img
                src="steps/step-7.png"
                className="img-fluid step-img img-thumbnail"
                alt="step screenshot"
              />
            </div>
          </div>
        );
      case 8:
        return (
          <div>
            <h2 className="step-text">
              {t("Step 8: Proceed to payment or cancel the request")}
            </h2>
            <div className="step-img-cont">
              <img
                src="steps/step-8.png"
                className="img-fluid step-img img-thumbnail"
                alt="step screenshot"
              />
            </div>
          </div>
        );
      case 9:
        return (
          <div>
            <h2 className="step-text">
              {t("Step 9: Click on PAY NOW button and choose a payment method")}
            </h2>
            <div className="step-img-cont">
              <img
                src="steps/step-9.png"
                className="img-fluid step-img img-thumbnail"
                alt="step screenshot"
              />
            </div>
          </div>
        );
      case 10:
        return (
          <div>
            <h2 className="step-text">{t("Paypal")}</h2>
            <div className="step-img-cont">
              <img
                src="steps/step-9a.png"
                className="img-fluid step-img img-thumbnail"
                alt="step screenshot"
              />
            </div>
          </div>
        );
      case 11:
        return (
          <div>
            <h2 className="step-text">{t("Master/Visa Card")}</h2>
            <div className="step-img-cont">
              <img
                src="steps/step-9b.png"
                className="img-fluid step-img img-thumbnail"
                alt="step screenshot"
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="walkthrough-container">
      <div className="steps-nav-cont">
        <Navbar />
      </div>
      <div className="walkthrough-content">
        <div className="step-content">{renderStepContent()}</div>
      </div>
      <div className="button-container">
        <button
          className="step-btn"
          onClick={handlePrevStep}
          disabled={currentStep === 1}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-arrow-left-square-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z" />
          </svg>
        </button>

        <button
          className="step-btn"
          onClick={handleNextStep}
          disabled={currentStep === 11}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-arrow-right-square-fill"
            viewBox="0 0 16 16"
          >
            <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Walkthrough;
