import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
import "./Wishlist.css";
import axios from "axios";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

function Wishlist() {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user.id;
  const userToken = user?.token;
  const { t } = useTranslation();

  const [allApartments, setAllApartments] = useState([]);

  const [wishlist, setWishlist] = useState([]);

  useEffect(() => {
    axios
      .get(`https://citynew.onrender.com/user/${userId}`)
      .then((response) => {
        setWishlist(response.data.wishlist);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId]);

  useEffect(() => {
    axios
      .get(`https://citynew.onrender.com/appartments/getAllAppart`)
      .then((response) => {
        setAllApartments(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const filteredApartments = allApartments.filter((apartment) =>
    wishlist.includes(apartment.id)
  );

  const handleDeleteFromWishlist = (id) => {
    fetch(`https://citynew.onrender.com/user/rmwishlist/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          // Delete item from wishlist successfully
          return response.json();
        } else {
          throw new Error("Failed to delete item from wishlist");
        }
      })
      .then((data) => {
        console.log(data); // Handle the response data

        // Refetch the wishlist
        axios
          .get(`https://citynew.onrender.com/user/${userId}`)
          .then((response) => {
            setWishlist(response.data.wishlist);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error.message); // Handle any errors that occurred during the deletion
      });
  };

  return (
    <div className="wishlist_page">
      <Navbar />
      <div className="content_page">
        <div className="upper__space"></div>
        <div className="upper__space wishlist_title">
          <h1>{t("WISHLIST")}</h1>
        </div>
        <div className="wishlist__body">
          <div className="wishlist__content">
            {filteredApartments.length === 0 ? (
              <h2 className="text-light fw-semibold text-center">
                {t("Nothing is here yet")}
              </h2>
            ) : (
              filteredApartments.map((apart) => (
                <div className="row pb-5">
                  <div className="col">
                    <Carousel fade>
                      {apart.img.map((img) => (
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src={img}
                            alt="apartment"
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                  <div className="col wishlist_description d-flex flex-column justify-content-center">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-warning btn-sm"
                        onClick={() => handleDeleteFromWishlist(apart.id)}
                      >
                        <FontAwesomeIcon icon={faX} />
                      </button>
                    </div>
                    <h1>
                      <Link to={`/details/${apart.id}`} className="text-light">
                        {apart.name}
                      </Link>{" "}
                    </h1>
                    <p>{apart.description} </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Wishlist;
