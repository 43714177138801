import React, { useState } from "react";
import Paynow from "./Paynow";
import RequestsPage from "../Requests/RequestsPage";

export default function Payment() {
  // eslint-disable-next-line no-unused-vars
  const [isApproved, setIsApproved] = useState(false);
  return (
    <div className="Payment-container">
      {isApproved ? <Paynow /> : <RequestsPage />}
    </div>
  );
}
